const MIDOL_ENDPOINT = {
  ADMIN: {
    LOGIN: 'auth/admin/login',
    LOGOUT: 'auth/admin/logout',
  },
  USERS: {
    GET_USERS: '/admin/users',
    UPDATE_USER: '/admin/users',
    SET_FAVORITE_KOL: '/admin/users/favorite',
  },
  REQUEST: {
    GET_REQUEST: '/admin/requests/kol',
    APPROVE_STATUS: '/admin/requests/kol',
    KYC_REQUEST: '/admin/requests/kol/kyc',
  },
  TRANSACTION: {
    GET_TRANSACTION: '/admin/transactions',
    GET_TOTAL_TRANSACTIONs: '/admin/transactions/total',
    UPDATE_TRANSACTION: '/admin/transactions',
  },
  MEDIA: {
    GET_PRESIGNED_URL: '/media/get-presigned-url',
  },
  DASHBOARD: {
    GET_STATISTIC: '/admin/dashboard/statistic',
    GET_REPORT: '/admin/dashboard/report'
  },
  BOOKING_REQUEST: {
    GET_REQUEST: '/admin/bookings',
  },
};

export { MIDOL_ENDPOINT };
