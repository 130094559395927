/* eslint-disable @typescript-eslint/no-unused-vars */
// import MenuIcon from '@mui/icons-material/Menu';
import {
  Container,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Popover,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getToken } from 'src/shared/services/storage.service';
import { AuthState } from 'src/store/Auth/auth.slice';
import { globalSelector } from 'src/store/global';
import { useAppSelector } from 'src/store/hooks';
import { ReactComponent as LogoSvg } from '../../assets/icons/primary-logo.svg';
import { RootState } from '../../store';
import authActions from '../../store/Auth/auth.action';
import SampleAppBar from '../AppBar';
import SampleDrawer from '../Drawer';
import ListItems from '../ListItems';
import MuiButton from '../MuiButton';
import styles from './Layout.module.scss';

const Layout = ({ children }: any) => {
  const [open] = useState(true);
  const [currentLang, setCurrentLang] = useState('en');
  const [userSetting, setUserSetting] = useState<HTMLElement | null>(null);
  const dispatch = useDispatch();
  const auth: AuthState = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const token = getToken();
  const { pageTitle } = useAppSelector(globalSelector);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const storedLang: string = i18n.language || 'en';
    setCurrentLang(storedLang);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const handleClickAvatar = (event: React.MouseEvent<HTMLElement>) => {
    setUserSetting(event.currentTarget);
  };

  const handleClosePopover = () => {
    setUserSetting(null);
  };

  const handleLoginClicked = () => {
    navigate('/dang-nhap');
  };

  const logout = async () => {
    setUserSetting(null);
    const result = await dispatch(authActions.logout({}) as any);
    const {
      payload: { data = false },
    } = result || {};
    if (data) {
      navigate('/');
    }
  };

  const renderAvatar = () => {
    return (
      <div
        className={`${styles['avatar-container']} ml-2 flex cursor-pointer items-center`}
      >
        <span className={`${styles['avatar-full-name']} mr-2 font-medium`}>
          {auth.user?.fullName}
        </span>
        <Avatar
          aria-describedby={
            Boolean(userSetting) ? 'profile-popover' : undefined
          }
          sx={{ bgcolor: '#532F91' }}
          onClick={handleClickAvatar}
          className={`${styles['avatar-component-container']}`}
        >
          {auth.user?.fullName.charAt(0).toUpperCase()}
        </Avatar>

        <Popover
          id={Boolean(userSetting) ? 'profile -popover' : undefined}
          open={Boolean(userSetting)}
          anchorEl={userSetting}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Paper sx={{ width: 160 }}>
            <MenuList>
              <MenuItem onClick={logout}>
                <div style={{ fontSize: 14, color: 'red' }}>{t('logout')}</div>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      </div>
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <SampleAppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <Typography
            component="h1"
            variant="h3"
            color="inherit"
            noWrap
            sx={{
              flexGrow: 1,
              fontWeight: 800,
              fontSize: '32px',
              lineHeight: '44px',
            }}
          >
            {auth.isLoggined && token && t(`PAGE_TITLES.${pageTitle}`)}
          </Typography>
          <div
            className={`grid grid-cols-2 gap-2 ${styles['right-container']}`}
          >
            {auth.isLoggined && token ? (
              renderAvatar()
            ) : (
              <MuiButton
                onClick={handleLoginClicked}
                typebutton="primary"
                variant="contained"
                size="small"
                className="p-2"
              >
                {t('LOGIN_FORM.login')}
              </MuiButton>
            )}
          </div>
        </Toolbar>
      </SampleAppBar>
      <SampleDrawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: [1],
          }}
        >
          <LogoSvg />
        </Toolbar>
        <Divider />
        {auth.isLoggined && token && (
          <List component="nav">
            <ListItems />
          </List>
        )}
      </SampleDrawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container
          maxWidth={false}
          sx={{ mt: 4, mb: 4 }}
          style={{
            paddingLeft: '32px',
            paddingRight: '32px',
          }}
        >
          <Grid item xs={24}>
            {children}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
