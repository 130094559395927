import { createSlice } from "@reduxjs/toolkit";
import requestAction from "./dashboard.action";
import { RootState } from "../index";

const initialState: any = {
  data: {},
  isLoading: false,
  error: "",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestAction.getReport.pending, (state) => {
        state.isLoading = true;
        state.isChangedStatus = false;
      })
      .addCase(requestAction.getReport.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          const result = action.payload.data || {};
          state.data = result;
        }
      })
      .addCase(
        requestAction.getReport.rejected,
        (state, action: Record<string, any>) => {
          state.isLoading = false;
          state.error = action.error;
        }
      )
  },
});

export const dashboardSliceActions = dashboardSlice.actions;
export const dashboardSelector = (state: RootState) => state.dashboardReducer;
export default dashboardSlice.reducer;
