import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import SignIn from './pages/SignIn';
import { PrivateRoute } from './components/PrivateRoute';
import { Suspense } from 'react';
import LoadingBackDrop from './components/LoadingBackDrop';

function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={<LoadingBackDrop open={true} handleClose={() => {}} />}
        >
          <Layout>
            <Routes>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route path="/dang-nhap" element={<SignIn />} />
              <Route path="*" element={<Dashboard />} />
            </Routes>
          </Layout>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
