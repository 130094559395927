import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { ReactComponent as HomeSvg } from "../../assets/icons/home.svg";

import { useAppDispatch } from "src/store/hooks";
import { globalSliceActions } from "src/store/global/global.slice";
import { useTranslation } from "react-i18next";

type Props = {
  id: string;
  label: string;
  path: string;
  pageTitle: string;
  icon: any;
};

const arrItem = [
  {
    id: "/",
    label: "overview",
    icon: "home",
    path: "/",
    pageTitle: "overview",
  },
];

const ListItems = () => {
  const [clickedItem, setClickedItem] = useState("arr1");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      default:
        setClickedItem(arrItem[0].id);
        break;
    }
  }, [location]);

  const handleClick = (item: Props) => {
    setClickedItem(item.id);
    navigate(item.path);
    dispatch(globalSliceActions.changePageTitle(item.pageTitle));
  };

  const renderIcon = (item: Props) => {
    let icon = null;

    switch (item.icon) {
      case "home":
        icon = <HomeSvg fill={clickedItem === item.id ? "white" : "black"} />;
        break;
      default:
        break;
    }

    return icon;
  };

  return (
    <React.Fragment>
      {arrItem.map((item) => (
        <ListItemButton
          key={item.id}
          className={clickedItem === item.id ? "item-active" : "item"}
          onClick={() => handleClick(item)}
          sx={{
            backgroundColor: (theme) =>
              clickedItem === item.id
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
            color: (theme) => (clickedItem === item.id ? "#fff" : "#000"),
            "&:hover": {
              background: "#532F91",
              color: "#fff",
            },
          }}
        >
          <ListItemIcon
            style={{
              minWidth: "32px",
            }}
            className="midol-menu-item"
          >
            {renderIcon(item)}
          </ListItemIcon>
          <ListItemText primary={t(`PAGE_TITLES.${item.label}`)} />
        </ListItemButton>
      ))}
    </React.Fragment>
  );
};

export default ListItems;
