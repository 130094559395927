import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@mui/material";
import "./index.scss";
import { separateNumber } from "src/shared/utils/common.util";

const TABLE_HEAD = [
  "Tiêu chí",
  "Số lượng",
  "Cách tính",
];

const TableComponent = ({
  data,
  isLoading,
}: {
  data: any;
  isLoading?: boolean;
}) => {

  return (
    <div className="table-wrap">
      <TableContainer className="table-container">
        <Table
          sx={{ minWidth: 650, maxHeight: "460px", background: '#fff' }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow className="profile-table-head">
              {TABLE_HEAD.map((item: string, index: number) => (
                <TableCell key={`${item}-${index}`} colSpan={index === 0 ? 2 : 1} className="profile-cell">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="profile-table-body">
            <TableRow>
              <TableCell colSpan={2} width="10%">Số lượng truy cập</TableCell>
              <TableCell width="10%">{separateNumber(data.SoLuongTruyCap)}</TableCell>
              <TableCell width="10%">Tính từ thời điểm hoạt động đến thời điểm thống kê</TableCell>
            </TableRow>
            {/* start */}
            <TableRow>
              <TableCell width="10%" colSpan={1} rowSpan={2}>Số lượng người bán</TableCell>
              <TableCell width="10%">Tổng số người bán</TableCell>
              <TableCell width="10%">{data.SoNguoiBan}</TableCell>
              <TableCell width="10%">Tính từ thời điểm hoạt động đến thời điểm thống kê</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="10%">Số lượng người bán mới</TableCell>
              <TableCell width="10%">{data.SoNguoiBanMoi}</TableCell>
              <TableCell width="10%">Tính thời điểm năm báo cáo từ 01/01/2023 đến thời điểm báo cáo</TableCell>
            </TableRow>
            {/* end */}
            {/* start */}
            <TableRow>
              <TableCell width="10%" colSpan={1} rowSpan={2}>Số lượng sản phẩm</TableCell>
              <TableCell width="10%">Tổng số sản phẩm (SKU)</TableCell>
              <TableCell width="10%">{data.TongSoSanPham || 'Chưa có'}</TableCell>
              <TableCell width="10%">Tính từ thời điểm hoạt động đến thời điểm thống kê</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="10%">Số sản phẩm đăng bán mới</TableCell>
              <TableCell width="10%">{data.SoSanPhamMoi || 'Chưa có'}</TableCell>
              <TableCell width="10%">Tính thời điểm năm báo cáo từ 01/01/2023 đến thời điểm báo cáo</TableCell>
            </TableRow>
            {/* end */}
            <TableRow>
              <TableCell width="10%" colSpan={1} rowSpan={3}>Số lượng giao dịch</TableCell>
              <TableCell width="10%">Tổng số đơn hàng</TableCell>
              <TableCell width="10%">{data.SoLuongGiaoDich}</TableCell>
              <TableCell width="10%">Tính thời điểm năm báo cáo từ 01/01/2023 đến thời điểm báo cáo</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="10%">Tổng số đơn hàng thành công</TableCell>
              <TableCell width="10%">{data.TongSoDonHangThanhCong}</TableCell>
              <TableCell width="10%">Tính thời điểm năm báo cáo từ 01/01/2023 đến thời điểm báo cáo</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="10%">Tổng số đơn hàng không thành công</TableCell>
              <TableCell width="10%">{data.TongSoDonHangThatBai}</TableCell>
              <TableCell width="10%">Tính thời điểm năm báo cáo từ 01/01/2023 đến thời điểm báo cáo</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="10%" colSpan={2}>Tổng giá trị giao dịch</TableCell>
              <TableCell width="10%">{separateNumber(data.TongGiaTriGiaoDich, ',')}</TableCell>
              <TableCell width="10%">Tổng số này ứng với tổng số đơn hàng giao dịch thành công (Tính thời điểm năm báo cáo từ 01/01/2023 đến thời điểm báo cáo)</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableComponent;
