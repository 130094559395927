import Table from './Table';
import { dashboardSelector } from "src/store/dashboard/dashboard.slice";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import dashboardAction from 'src/store/dashboard/dashboard.action';
import './index.scss';
import { useEffect } from 'react';

const Dashboard = () => {
  const { data, isLoading } = useAppSelector(dashboardSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(dashboardAction.getReport({}));
  }, [dispatch]);

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ fontWeight: '600', fontSize: '20px' }}>BÁO CÁO SỐ LIỆU</div>
      <div style={{ fontWeight: '600' }}>Từ ngày 01/01/2023 – đến thời điểm truy cập</div>
      <div className='report-table'>
        <Table data={data} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default Dashboard;
