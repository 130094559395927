import apiService from "../../shared/services/api.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MIDOL_ENDPOINT } from "../../shared/constants/endpoints.constant";

class DashboardActions {
  getReport = createAsyncThunk(
    "dashboard/getReport",
    async (
      requestBody: {},
      { rejectWithValue }
    ) => {
      try {
        const res: any = await apiService.post(
          `${MIDOL_ENDPOINT.DASHBOARD.GET_REPORT}`,
          {}
        );
        return res.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default new DashboardActions();
